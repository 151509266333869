<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="maintenance/repair-orders"
    >
      <el-button
        v-if="$auth('客服导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="visible=true">
        {{$l("common.search", "客服报修导出")}}
      </el-button>
      <el-button
        v-if="$auth('维修上报管理客服报修')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.customerAdd.open(0)">
        {{$l("common.add", "新增")}}
      </el-button>




      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('project.name','维保班组')" prop="teamName">
            <vm-autocomplete
              :filter.sync="filter.teamName"

              url="organization/team/list"></vm-autocomplete>
          </vm-search>
          <vm-search label="队长姓名">
            <el-input v-model.trim="filter.teamLeaderName" clearable></el-input>
          </vm-search>
          <vm-search label="队长电话">
            <el-input v-model.trim="filter.teamLeaderPhone" clearable></el-input>
          </vm-search>
          <vm-search label="工单编号">
            <el-input v-model.trim="filter.orderCode" clearable></el-input>
          </vm-search>

          <vm-search label="报修时间">
            <el-date-picker
              v-model="filter.seTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>

          <vm-search label="楼盘">
            <vm-autocomplete
              :filter.sync="filter.realEstateName"
              url="real-estate/list"></vm-autocomplete>
          </vm-search>

          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>

          <vm-search label="是否滑梯">
            <el-select v-model="filter.elevatorFalling">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="是否停梯">
            <el-select v-model="filter.elevatorStopped">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="是否困人">
            <el-select v-model="filter.peopleTrapped">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
        </div>
      </template>
      <el-table-column
        align="center"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column prop="teamName" :label="$l('project.name','维保班组')">
        <el-table-column prop="teamLeaderName" label="队长">
          <template slot-scope="scope">
            <div>
              {{scope.row.teamName}}
            </div>
            <div> {{scope.row.teamLeaderName}}/{{scope.row.teamLeaderPhone}}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="assigneeName" label="工单责任人姓名/电话">
        <template slot-scope="scope">
          {{scope.row.memberName}}/{{scope.row.memberPhone}}
        </template>
      </el-table-column>
      <el-table-column prop="reportedTime" label="报修时间" align="center"></el-table-column>
      <el-table-column prop="reporterName" label="报修人/电话">
        <template slot-scope="scope">
          {{scope.row.reporterName}}/{{scope.row.reporterPhone}}
        </template>
      </el-table-column>
      <el-table-column prop="usingCompanyName" label="使用单位" align="center"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘/楼宇" align="center">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}/{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center" width="180">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.elevatorDetail.open(scope.row.elevatorId)">{{scope.row.regCode}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" label="内部编号" align="center"></el-table-column>
      <el-table-column prop="elevatorFalling" label="是否滑梯" align="center">
        <template slot-scope="scope">
          {{scope.row.elevatorFalling?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorStopped" label="是否停梯" align="center">
        <template slot-scope="scope">
          {{scope.row.elevatorStopped?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="peopleTrapped" label="是否困人" align="center">
        <template slot-scope="scope">
          {{scope.row.peopleTrapped?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
        <template slot-scope="scope">
          <el-button v-if="$auth('维修上报管理取消')" type="danger" @click="$refs.cancelPage.open(scope.row.id)">{{$l("common.delete", "取消")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <el-dialog :visible="visible">
      开始时间：<el-date-picker v-model="reportedTimeFrom" :picker-options="pickerOptions">
      </el-date-picker>
      结束时间：<el-date-picker
        v-model="reportedTimeTo"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="exportExcelData">确 定</el-button>
      </span>
    </el-dialog>


    <repair-verification ref="verificationPage" @save-success="getList(-1)"></repair-verification>
    <repair-dispatch ref="repairDispatch" @save-success="getList(-1)"></repair-dispatch>
    <customer-add ref="customerAdd" @save-success="getList(-1)"></customer-add>
    <customer-add-record ref="customerAddRecord" @save-success="getList(-1)"></customer-add-record>
    <repair-cancel ref="cancelPage" @save-success="getList(-1)"></repair-cancel>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <elevator-detail ref="elevatorDetail" @save-success="getList(-1)"></elevator-detail>
  </div>
</template>
<script>
  import EditPage from "../reportOrder/RepairDetailNew.vue";
  import RepairVerification from "@/views/repair/RepairVerification";
  import RepairCancel from "@/views/repair/RepairReportCancel";
  import RepairDispatch from "../../maintenance/maintenancePlan/RepairDispatch";
  import CustomerAdd from "./CustomerAddTeleRecord";
  import VmAutocomplete from "@/components/VmAutocomplete";
  import elevatorDetail from "@/views/elevator/ElevatorDetail";
  import {export2Excel} from "@/util";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";
  export default {
    components: {EditPage,RepairVerification,RepairCancel,RepairDispatch,CustomerAdd,VmAutocomplete,elevatorDetail},
    data() {
      return {
        filter: {

          orderColumnName:"id",
          companyId: loginUtil.getCompanyCode(),
          source:"customer_service",
        },
        visible:false,
        reportedTimeFrom:"",
        reportedTimeTo:"",
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      exportExcelData() {
        // reportedTimeFrom
        const title={
          reportDate:"报修日期",
          reportTime:"时间",
          usingCompany:"使用单位",
          reporterName:"报修人",
          faultDescribe:"故障描述",
          isRepeat:"是否有重复报修",
          phone:"联系电话",
          peopleTrapped:"是否困人",
          elevatorFalling:"是否滑梯",
          useTypeDesc:"梯型",
          leaderName:"队长",
          maintainer:"维修人员",
          brandName:"品牌",
          faultCause:"故障原因",
          faultType:"故障类别",
          arrivedTime:"到达时间",
          completedTime:"修复时间",
          regDate:"维修单登记日期",
          remark:"备注",
        };

        console.log(this.reportedTimeFrom,this.reportedTimeTo,302);
        this.$http.get("maintenance/repair-report/export/list",{source:"customer_service",reportedTimeFrom:moment(this.reportedTimeFrom).format("YYYY-MM-DD")+" 00:00:00",reportedTimeTo:moment(this.reportedTimeTo).format("YYYY-MM-DD")+" 23:59:59",pageSize:30}).then(res=>{
          this.visible=false;
          // console.log(moment(),export2Excel());
          export2Excel(title,res,`故障报修-客服报修-${res.length}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      handleTime() {
        if(this.filter.seTime !=null) {
          this.filter.reportedTimeFrom = this.filter.seTime[0];
          this.filter.reportedTimeTo = this.filter.seTime[1];
        }
      },

      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      toTask(row) {
        const arr = [];
        if (row.tasks instanceof Array) {
          row.tasks.forEach(item=>{
            arr.push(item.id);
          });
        }

        this.$http.post("/maintenance/repair-order/from-task",arr).then(()=>{
          this.$message.success("已转任务");
        });
      },
      deleteRow(row) {
        this.$confirm("确定删除该条记录吗?", this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/repair-report/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success("该条记录" + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
